@import '@core/scss/core.scss';
@import './assets/scss/styles';

* {
    box-sizing: border-box;
    font-family: "Noto Sans JP", sans-serif !important;
    font-size: normal;
}

.input {
    &:focus {
        border: 1px solid #ff9f43 !important;
    }
}

.main-padding {
    padding: 85px 20px 20px 20px
}

.form-header {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #303030;
    white-space: nowrap;
}

.form-sub-head {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    color: #7A7A7A;
    width: 100%
}

.cancel-btn {
    width: 118px;
    height: 37px;
    left: 398px;
    top: 2063px;
    background: #F7F7F7;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #303030;
    border: none;
    cursor: pointer;
}

.preview-btn{
    border: 1px solid #BABABA !important;
    background: #fff!important;
    min-width: 150px !important;
}

.popup-inner {
    padding: 20px;
}

.popup-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    width: 100%;
    color: #303030;
}

.popup-body {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #7A7A7A;
    width: 100%
}

.popup-cancel-btn {
    background: rgba(48, 48, 48, 0.05);
    border-radius: 5px;
    align-items: center;
    padding: 10px 22px;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #303030;
    cursor: pointer;
    outline: none;
    border: none;

    &:focus {
        outline: none;
    }
}

.popup-submit-btn {
    background: #003566;
    border-radius: 5px;
    padding: 10px 22px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
    border: none;
    width: 100%;

    &:focus {
        outline: none;
    }
}

.form-validation-style {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FF6262;
}

.custom-invalid {
    border: 1px solid #EA5455 !important
}

.pagination-div {
    background: rgba(48, 48, 48, 0.05);
    border-radius: 16px;
    height: 30px;
    text-align: center;
    padding: 0px 10px;
}

.paginataion-btn {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: rgba(48, 48, 48, 0.45);
    padding: 5px 0px;
    cursor: pointer;
}

.pagination-btn-selected {
    background: #FAAA14;
    border-radius: 29px;
    width: 32px;
    height: 32px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    padding: 5px;
    cursor: pointer;
}

.pagination-count {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(48, 48, 48, 0.45);
}

.back-btn {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(48, 48, 48, 0.45);
}

.btn-cancel-sidebar {
    border: none;
    padding: 9px 23px;
    background: rgba(48, 48, 48, 0.05);
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.4px;
    height: 37px;
    color: #303030;
    white-space: nowrap;
}

.btn-confirm-sidebar {
    background-color: #FAAA14;
    padding: 9px 23px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #FFFFFF;
    height: 37px;
    border: none;
    white-space: nowrap;
}

.no-data {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #303030;
    padding-top: 50px;
}

@media screen and (max-width: 420px) {
    .otp-container {
        div {
            input {
                width: 30px !important;
                font-size: 18px;
                height: 30px !important;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .otp-container {
        div {
            input {
                width: 40px !important;
                font-size: 18px;
                height: 40px !important;
            }
        }
    }
}

.angular-editor .angular-editor-wrapper .angular-editor-textarea {
    overflow-y: auto !important;
    height: 50px;
    resize: none !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: url('../src/assets/icons/alert-triangle.svg') !important;
    padding-left: calc(1.45em + 0.876rem) !important;
    background-position: left calc(0.3625em + 0.219rem) center;
}

.name-class {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
}


li {
    .custom-icon-inactive {
        display: block;
        margin-right: 5px;
        width: 15px;
    }

    .custom-icon-active {
        display: none;
        margin-right: 5px;
    }

    .menu-text-hover {
        &:hover {
            color: rgba(48, 48, 48, 0.75) !important;
            background-color: #F7F7F7 !important;
        }
    }
}

.active {
    .custom-icon-inactive {
        display: none;
    }

    .custom-icon-active {
        display: block;
    }

    .menu-text-hover {
        &:hover {
            background-color: #F7F7F7 !important;
            color: #FFFFFF !important;
        }
    }

    li {
        .menu-text-hover {
            &:hover {
                color: rgba(48, 48, 48, 0.75) !important;
            }
        }
    }
}

.review-btn {
    width: 71px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #FAAA14;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #FAAA14;

    &:hover {
        background-color: #FAAA14;
        color: #FFFFFF;
    }
    &:disabled{
        opacity: .5;
        &:hover {
            background-color: #FFFFFF;
            color: #FAAA14;
        }
    }
}


.completed-btn {
    width: 71px;
    height: 30px;
    background: #F7F7F7;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: rgba(48, 48, 48, 0.45);
    border: none;
  }

  .profile {
    width: 32px;
    height: 32px;
    border-radius: 16px;
  }

.name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #303030;
}

.phone {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 1px;
    color: #808080;
}

th {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #303030;
    white-space: nowrap;
}

td {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #303030;
}
.warning-text{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #303030;
    margin:0;
  }

  
.search-input {
    border-radius: 0.357rem 0 0 0.357rem !important;
    width: 240px;
    height: 38px ;
    &:focus {
        border:  1px solid #d8d6de !important;
        box-shadow: none !important;
    }
}

.search-btn {
    border: none;
    right: 0;
    height: 38px;
    border-radius: 0 0.357rem 0.357rem 0;
    background: #F7F7F7;
    border: 1px solid #d8d6de;
    width: 38px;
    padding-bottom: 5px;
}

.unread-data-count{
    height: 20px !important;
    width: 20px !important;
    background-color:  #EE5F40 !important;
    color: white;
    border-radius: 10px;
    border: none;
    margin-left: 8px;
    padding: 0;
    font-size: 12px;
    text-align: center;
    line-height: 12px;
  }
  
  .invalid-flatpickr-date {
    .ng2-flatpickr-input-container {
       .form-control{ 
        border: 1px solid red !important;
      }
    }
  }

  .ng2-flatpickr-form-control{
    .ng2-flatpickr-input-container{
    .form-control:disabled,
    .form-control[readonly] {
        background-color: #ffffff !important;
    }
}
}
@media print {
    body * {
      visibility: hidden;
    }
    #invoiceDiv * {
      visibility: visible;
    }
  }


  .send-btn{
    min-width: 180px;
    width: fit-content;
    white-space:no-wrap;
    height: 40px !important;
    left: 398px;
    top: 2063px;
    background: #FFF;
    padding: 3px 14px;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #FAAA14 !important;
    border: 1px solid #FAAA14 !important;
    cursor: pointer;

  }