/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

textarea {
  resize: none;
  height: 144px !important;
}

textarea::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

textarea::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e0e0e0;
  border-radius: 10px;
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #FAAA14;
}

.custom-select {
  min-height: 43px !important;
  cursor: pointer;

  .ng-select-container {
    margin-top: 5px !important;
  }

  .ng-dropdown-panel-items {
    max-height: 200px !important;
    padding: 3px !important;
  }

  .ng-dropdown-panel-items::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .ng-dropdown-panel-items::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 10px;
  }

  .ng-dropdown-panel-items::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #FAAA14;
  }

  .ng-dropdown-panel {
    background-color: #fff !important;
    border: none;
    border-radius: 6px;
    margin-top: 23px !important;
    margin-left: -14px !important;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  }

  .ng-option {
    height: 40px !important;
    display: flex !important;
    align-items: center !important;
    padding-left: 5px !important;
    margin: 2px 0px;

    &.ng-option-selected {
      background-color: $primary !important;
      color: $white !important;

      &.ng-option-marked {
        background-color: $primary !important;
        color: $white !important;
      }

      .ng-option-label {
        font-weight: inherit !important;
      }
    }

    &.ng-option-marked {
      background-color: rgba($primary, 0.12) !important;
      color: $primary !important;
    }

    &.ng-option-disabled {
      color: $text-muted !important;
    }
  }

  &.error {
    .ng-select-container {
      border-color: $danger !important;
    }
  }

  &.ng-select-multiple {
    .ng-value {
      background-color: $primary !important;
      color: $white;
      border: none !important;
      font-size: 0.8rem !important;
      border-radius: 4px !important;
      display: flex;
      align-items: center;
      padding: 0px 10px;
      margin: 5px;

      .ng-value-icon {
        &.right {
          border: 0 !important;
        }

        &.left {
          border: 0 !important;
        }

        &:hover {
          background-color: transparent !important;
        }
      }

      .ng-value-icon {
        &.left {
          font-size: 1.1rem !important;
        }

        &.right {
          font-size: 1.1rem !important;
        }
      }
    }
  }

  //   .ng-arrow {
  //     background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaBAMAAABbZFH9AAAAG1BMVEUAAACRkZGRkZGSkpKRkZGSkpKSkpKRkZGRkZHLso+9AAAACHRSTlMA+1JoWo0vLFQDmmkAAABlSURBVBjTY6ALSACTbBAOazOYsggAUxEdBkCSuaMVxGGX6BABUo4djQUgrmJHhwFQqkMIrJJJoqOZwaKjUQHIhkg6g6QggEWiQ7Cj0QHIgkpCpaA6wbrgkiAphKSgArJTXRhoBgB9GRPswyvBqAAAAABJRU5ErkJggg==');
  //     background-size: 12px 12px, 10px 10px;
  //     background-repeat: no-repeat;
  //     height: 0.8rem !important;
  //     padding-right: 1.5rem;
  //     margin-left: 0;
  //     margin-top: 0;
  //     left: 0;
  //     border-style: none !important;
  //   }
  //   &.ng-select-opened > .ng-select-container .ng-arrow {
  //     top: 0px !important;
  //   }
  .ng-clear-wrapper {
    height: 18px;
  }
}

.custom-select-multi {
  height: fit-content !important;
}

.date-picker-readonly {
  .form-control[readonly] {
    background-color: #F7F7F7 !important;
    border: none;
  }
}

.custom-readonly {
  .form-control[readonly] {
    background-color: #fff;
  }
}

@media (max-width: 991.98px) {
  .sidebar-left.h-100 {
    height: auto !important;
  }

  .chat-section.main-padding {
    padding: 60px 0 20px 0 !important;
  }
}

// .ng-select .ng-select-container .ng-value-container .ng-input > input {
//   height: 100% !important;
// }